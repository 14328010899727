import { Stack, Card, TextInput, Text, Divider, Group } from "@mantine/core";
import { IconCircleCheck, IconMail } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { searchClientsByEmail } from "../Protocol";
export let searching_email = false;
export let EmailName: any = "";
export interface UserLawFirm {
  law_firm_name: string;
  street_address_1: string;
  street_address_2: string;
  street_address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  phone_number: string;
}
export const law_firm_of_user: UserLawFirm = {
  law_firm_name: "",
  street_address_1: "",
  street_address_2: "",
  street_address: "",
  city: "",
  state: "",
  zip: "",
  email: "",
  phone_number: "",
};

export const ClientEmailSearch = () => {
  const [emailError, setEmailError] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [email, setEmail] = useState<null | string>(null);
  

  const checkEmail = () => {
    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError(false);
      setEmailValid(false);
      searching_email = false;
      return;
    }
  
    searchClientsByEmail(email.trim()).then((clients) => {
      law_firm_of_user.email = email; // Copy email for manual entry

      if (clients.length > 0) {
        setEmailError(false);
        setEmailValid(true);
      }
      else{
        setEmailError(true);
        setEmailValid(false);
        searching_email = false;
        return;
      }
  
      // Object.entries(clients[0] as UserLawFirm).forEach(([key, value]) => {
      //   law_firm_of_user[key as keyof UserLawFirm] = value;
      //   console.log(key, value)
      // });
      setEmailError(false);
      setEmailValid(true);
      searching_email = false;
    });
  };

  useEffect(() => {
    EmailName = email;
    if (!email || email.trim() === "") {
      setEmailError(false);
      setEmailValid(false);
      searching_email = false;
      return;
    }
  
    const debounceTimeout = setTimeout(() => {
      checkEmail();
    }, 500);
  
    return () => {
      clearTimeout(debounceTimeout);
      searching_email = false;
    };
  }, [email]);

  return (
    <Stack>
      {!emailError && (
        <Card>
          <Text>Please enter your email</Text>
          <Divider />

          <TextInput
            required
            leftSection={<IconMail />}
            value={email || ""}
            onChange={(e) => {
              setEmail(e.currentTarget.value);
            }}
          />

          {emailValid && (
            <Group>
              <IconCircleCheck color="green" />
              <Text fz="xs">We have your information in our system.</Text>
            </Group>
          )}
          <Divider />
        </Card>
      )}

      {emailError && (
        <Card>
          <Text>We couldn't find this email in our system.</Text>
          <Text>Please fill out your information instead</Text>
          <TextInput
            required
            label="Email Address"
            leftSection={<IconMail />}
            value={email || ""}
            onChange={(e) => {
              setEmail(e.currentTarget.value);
            }}
          />
          <TextInput
            onChange={(e) => {
              law_firm_of_user.law_firm_name = e.currentTarget.value;
            }}
            required
            label="Name of your law firm"
          />
          <TextInput
            onChange={(e) => {
              law_firm_of_user.street_address_1 = e.currentTarget.value;
              law_firm_of_user.street_address =
                law_firm_of_user.street_address_1 +
                "," +
                law_firm_of_user.street_address_2;
            }}
            required
            label="Street address line 1"
          />
          <TextInput
            onChange={(e) => {
              law_firm_of_user.street_address_2 = e.currentTarget.value;
              law_firm_of_user.street_address =
                law_firm_of_user.street_address_1 +
                "," +
                law_firm_of_user.street_address_2;
            }}
            label="Street address line 2"
          />
          <TextInput
            onChange={(e) => {
              law_firm_of_user.city = e.currentTarget.value;
            }}
            required
            label="City"
          />
          <TextInput
            onChange={(e) => {
              law_firm_of_user.state = e.currentTarget.value;
            }}
            required
            maxLength={2}
            label="State (abbreviated)"
          />
          <TextInput
            onChange={(e) => {
              law_firm_of_user.zip = e.currentTarget.value;
            }}
            required
            label="Zip Code"
          />
          <TextInput
            onChange={(e) => {
              law_firm_of_user.phone_number = e.currentTarget.value;
            }}
            required
            label="Phone Number"
          />
        </Card>
      )}
    </Stack>
  );
};
